export const SAFETY_NET_ALL_MAILBOXES_CHART_DATA_REQUEST =
  'SAFETY_NET_ALL_MAILBOXES_CHART_DATA_REQUEST';
export const SAFETY_NET_ALL_MAILBOXES_CHART_DATA_REQUEST_SUCCESS =
  'SAFETY_NET_ALL_MAILBOXES_CHART_DATA_REQUEST_SUCCESS';
export const SAFETY_NET_ALL_MAILBOXES_CHART_DATA_REQUEST_FAIL =
  'SAFETY_NET_ALL_MAILBOXES_CHART_DATA_REQUEST_FAIL';

export const SAFETY_NET_ALL_MAILBOXES_TIMELINE_DATA_REQUEST =
  'SAFETY_NET_ALL_MAILBOXES_TIMELINE_DATA_REQUEST';
export const SAFETY_NET_ALL_MAILBOXES_TIMELINE_DATA_REQUEST_SUCCESS =
  'SAFETY_NET_ALL_MAILBOXES_TIMELINE_DATA_REQUEST_SUCCESS';
export const SAFETY_NET_ALL_MAILBOXES_TIMELINE_DATA_REQUEST_FAIL =
  'SAFETY_NET_ALL_MAILBOXES_TIMELINE_DATA_REQUEST_FAIL';

export const SAFETY_NET_SINGLE_MAILBOX_CHART_DATA_REQUEST =
  'SAFETY_NET_SINGLE_MAILBOX_CHART_DATA_REQUEST';
export const SAFETY_NET_SINGLE_MAILBOX_CHART_DATA_REQUEST_SUCCESS =
  'SAFETY_NET_SINGLE_MAILBOX_CHART_DATA_REQUEST_SUCCESS';
export const SAFETY_NET_SINGLE_MAILBOX_CHART_DATA_REQUEST_FAIL =
  'SAFETY_NET_SINGLE_MAILBOX_CHART_DATA_REQUEST_FAIL';

export const SAFETY_NET_SINGLE_MAILBOX_TIMELINE_DATA_REQUEST =
  'SAFETY_NET_SINGLE_MAILBOX_TIMELINE_DATA_REQUEST';
export const SAFETY_NET_SINGLE_MAILBOX_TIMELINE_DATA_REQUEST_SUCCESS =
  'SAFETY_NET_SINGLE_MAILBOX_TIMELINE_DATA_REQUEST_SUCCESS';
export const SAFETY_NET_SINGLE_MAILBOX_TIMELINE_DATA_REQUEST_FAIL =
  'SAFETY_NET_SINGLE_MAILBOX_TIMELINE_DATA_REQUEST_FAIL';

export const SAFETY_NET_STATISTICS_REQUEST = 'SAFETY_NET_STATISTICS_REQUEST';
export const SAFETY_NET_STATISTICS_REQUEST_SUCCESS = 'SAFETY_NET_STATISTICS_REQUEST_SUCCESS';
export const SAFETY_NET_STATISTICS_REQUEST_FAIL = 'SAFETY_NET_STATISTICS_REQUEST_FAIL';

export const SAFETY_NET_VALIDATIONS_REQUEST = 'SAFETY_NET_VALIDATIONS_REQUEST';
export const SAFETY_NET_VALIDATIONS_REQUEST_SUCCESS = 'SAFETY_NET_VALIDATIONS_REQUEST_SUCCESS';
export const SAFETY_NET_VALIDATIONS_REQUEST_FAIL = 'SAFETY_NET_VALIDATIONS_REQUEST_FAIL';

export const SAFETY_NET_VALIDATIONS_SAVE_REQUEST = 'SAFETY_NET_VALIDATIONS_SAVE_REQUEST';
export const SAFETY_NET_VALIDATIONS_SAVE_REQUEST_SUCCESS =
  'SAFETY_NET_VALIDATIONS_SAVE_REQUEST_SUCCESS';
export const SAFETY_NET_VALIDATIONS_SAVE_REQUEST_FAIL = 'SAFETY_NET_VALIDATIONS_SAVE_REQUEST_FAIL';
