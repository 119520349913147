import {
  SAFETY_NET_ALL_MAILBOXES_CHART_DATA_REQUEST,
  SAFETY_NET_ALL_MAILBOXES_CHART_DATA_REQUEST_FAIL,
  SAFETY_NET_ALL_MAILBOXES_CHART_DATA_REQUEST_SUCCESS,
  SAFETY_NET_ALL_MAILBOXES_TIMELINE_DATA_REQUEST,
  SAFETY_NET_ALL_MAILBOXES_TIMELINE_DATA_REQUEST_FAIL,
  SAFETY_NET_ALL_MAILBOXES_TIMELINE_DATA_REQUEST_SUCCESS,
  SAFETY_NET_SINGLE_MAILBOX_CHART_DATA_REQUEST,
  SAFETY_NET_SINGLE_MAILBOX_CHART_DATA_REQUEST_FAIL,
  SAFETY_NET_SINGLE_MAILBOX_CHART_DATA_REQUEST_SUCCESS,
  SAFETY_NET_SINGLE_MAILBOX_TIMELINE_DATA_REQUEST,
  SAFETY_NET_SINGLE_MAILBOX_TIMELINE_DATA_REQUEST_FAIL,
  SAFETY_NET_SINGLE_MAILBOX_TIMELINE_DATA_REQUEST_SUCCESS,
  SAFETY_NET_STATISTICS_REQUEST,
  SAFETY_NET_STATISTICS_REQUEST_FAIL,
  SAFETY_NET_STATISTICS_REQUEST_SUCCESS,
  SAFETY_NET_VALIDATIONS_REQUEST,
  SAFETY_NET_VALIDATIONS_REQUEST_FAIL,
  SAFETY_NET_VALIDATIONS_REQUEST_SUCCESS,
  SAFETY_NET_VALIDATIONS_SAVE_REQUEST,
  SAFETY_NET_VALIDATIONS_SAVE_REQUEST_FAIL,
  SAFETY_NET_VALIDATIONS_SAVE_REQUEST_SUCCESS,
} from '../Constants/safetyNetConstants';

function safetyNetMailboxChartDataReducer(state = { data: null }, action) {
  switch (action.type) {
    case SAFETY_NET_SINGLE_MAILBOX_CHART_DATA_REQUEST:
      return { loading: true, data: null };
    case SAFETY_NET_SINGLE_MAILBOX_CHART_DATA_REQUEST_SUCCESS:
      return { loading: false, data: action.payload };
    case SAFETY_NET_SINGLE_MAILBOX_CHART_DATA_REQUEST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function safetyNetMailboxTimelineDataReducer(state = { data: null }, action) {
  switch (action.type) {
    case SAFETY_NET_SINGLE_MAILBOX_TIMELINE_DATA_REQUEST:
      return { loading: true, data: null };
    case SAFETY_NET_SINGLE_MAILBOX_TIMELINE_DATA_REQUEST_SUCCESS:
      return { loading: false, data: action.payload };
    case SAFETY_NET_SINGLE_MAILBOX_TIMELINE_DATA_REQUEST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function safetyNetAllMailboxesChartDataReducer(state = { data: null }, action) {
  switch (action.type) {
    case SAFETY_NET_ALL_MAILBOXES_CHART_DATA_REQUEST:
      return { loading: true, data: null };
    case SAFETY_NET_ALL_MAILBOXES_CHART_DATA_REQUEST_SUCCESS:
      return { loading: false, data: action.payload };
    case SAFETY_NET_ALL_MAILBOXES_CHART_DATA_REQUEST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function safetyNetAllMailboxesTimelineDataReducer(state = { data: null }, action) {
  switch (action.type) {
    case SAFETY_NET_ALL_MAILBOXES_TIMELINE_DATA_REQUEST:
      return { loading: true, data: null };
    case SAFETY_NET_ALL_MAILBOXES_TIMELINE_DATA_REQUEST_SUCCESS:
      return { loading: false, data: action.payload };
    case SAFETY_NET_ALL_MAILBOXES_TIMELINE_DATA_REQUEST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function safetyNetStatisticsReducer(state = { data: null }, action) {
  switch (action.type) {
    case SAFETY_NET_STATISTICS_REQUEST:
      return { loading: true, data: null };
    case SAFETY_NET_STATISTICS_REQUEST_SUCCESS:
      return { loading: false, data: action.payload };
    case SAFETY_NET_STATISTICS_REQUEST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function safetyNetValidationsReducer(state = { safetyNetValidationsData: null }, action) {
  switch (action.type) {
    case SAFETY_NET_VALIDATIONS_REQUEST:
      return { loading: true, safetyNetValidationsData: null };
    case SAFETY_NET_VALIDATIONS_REQUEST_SUCCESS:
      return { loading: false, safetyNetValidationsData: action.payload };
    case SAFETY_NET_VALIDATIONS_REQUEST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function safetyNetValidationsSaveReducer(state = { safetyNetSaveValidationsData: null }, action) {
  switch (action.type) {
    case SAFETY_NET_VALIDATIONS_SAVE_REQUEST:
      return { loading: true, safetyNetSaveValidationsData: null };
    case SAFETY_NET_VALIDATIONS_SAVE_REQUEST_SUCCESS:
      return { loading: false, safetyNetSaveValidationsData: action.payload };
    case SAFETY_NET_VALIDATIONS_SAVE_REQUEST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export {
  safetyNetMailboxChartDataReducer,
  safetyNetMailboxTimelineDataReducer,
  safetyNetAllMailboxesChartDataReducer,
  safetyNetAllMailboxesTimelineDataReducer,
  safetyNetStatisticsReducer,
  safetyNetValidationsReducer,
  safetyNetValidationsSaveReducer,
};
