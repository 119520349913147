export const CONVERSATION_REQUEST = 'CONVERSATION_REQUEST';
export const CONVERSATION_REQUEST_SUCCESS = 'CONVERSATION_REQUEST_SUCCESS';
export const CONVERSATION_REQUEST_FAIL = 'CONVERSATION_REQUEST_FAIL';

export const CONVERSATION_SAVE_REQUEST = 'CONVERSATION_SAVE_REQUEST';
export const CONVERSATION_SAVE_REQUEST_CLOSE = 'CONVERSATION_SAVE_REQUEST_CLOSE';
export const CONVERSATION_SAVE_REQUEST_SUCCESS = 'CONVERSATION_SAVE_REQUEST_SUCCESS';
export const CONVERSATION_SAVE_REQUEST_FAIL = 'CONVERSATION_SAVE_REQUEST_FAIL';

export const CONVERSATION_DELETE_REQUEST = 'CONVERSATION_DELETE_REQUEST';
export const CONVERSATION_DELETE_SUCCESS = 'CONVERSATION_DELETE_SUCCESS';
export const CONVERSATION_DELETE_FAIL = 'CONVERSATION_DELETE_FAIL';
