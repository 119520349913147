const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth"
  });
};

const chartJsTooltipRemove = () => {
  const chartJsObj = document.querySelector("#chartjs-tooltip");
  chartJsObj && (chartJsObj.remove());
}

const queryString = (params) => {
  return Object.keys(params).map((key) => {
    if(params[key]) return  encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
  }).filter(prm => prm).join('&');
}

export { scrollToTop, chartJsTooltipRemove, queryString }