export const PLAN_DETAILS_REQUEST         = 'PLAN_DETAILS_REQUEST';
export const PLAN_DETAILS_REQUEST_SUCCESS = 'PLAN_DETAILS_REQUEST_SUCCESS';
export const PLAN_DETAILS_REQUEST_FAIL    = 'PLAN_DETAILS_REQUEST_FAIL';

export const PLAN_DETAILS_SAVE_REQUEST    = 'PLAN_DETAILS_SAVE_REQUEST';
export const PLAN_DETAILS_SAVE_SUCCESS    = 'PLAN_DETAILS_SAVE_SUCCESS';
export const PLAN_DETAILS_SAVE_FAIL       = 'PLAN_DETAILS_SAVE_FAIL';

export const GET_CURRENT_PLAN_REQUEST     = 'GET_CURRENT_PLAN_REQUEST';
export const GET_CURRENT_PLAN_SUCCESS     = 'GET_CURRENT_PLAN_SUCCESS';
export const GET_CURRENT_PLAN_FAIL        = 'GET_CURRENT_PLAN_FAIL';

export const USER_PLAN_SAVE_REQUEST       = 'USER_PLAN_SAVE_REQUEST';
export const USER_PLAN_SAVE_SUCCESS       = 'USER_PLAN_SAVE_SUCCESS';
export const USER_PLAN_SAVE_FAIL          = 'USER_PLAN_SAVE_FAIL';

export const PLAN_ALL_DETAILS_REQUEST     = 'PLAN_ALL_DETAILS_REQUEST';
export const PLAN_ALL_DETAILS_REQUEST_SUCCESS = 'PLAN_ALL_DETAILS_REQUEST_SUCCESS';
export const PLAN_ALL_DETAILS_REQUEST_FAIL = 'PLAN_ALL_DETAILS_REQUEST_FAIL';