export const SPAM_RATES_REQUEST = 'SPAM_RATES_REQUEST';
export const SPAM_RATES_REQUEST_SUCCESS = 'SPAM_RATES_REQUEST_SUCCESS';
export const SPAM_RATES_REQUEST_FAIL = 'SPAM_RATES_REQUEST_FAIL';

export const PROCESSED_EMAIL_REQUEST = 'PROCESSED_EMAIL_REQUEST';
export const PROCESSED_EMAIL_REQUEST_SUCCESS = 'PROCESSED_EMAIL_REQUEST_SUCCESS';
export const PROCESSED_EMAIL_REQUEST_FAIL = 'PROCESSED_EMAIL_REQUEST_FAIL';

export const REPUTATION_INCREASES_REPORT_REQUEST = 'REPUTATION_INCREASES_REPORT_REQUEST';
export const REPUTATION_INCREASES_REPORT_REQUEST_SUCCESS = 'REPUTATION_INCREASES_REPORT_REQUEST_SUCCESS';
export const REPUTATION_INCREASES_REPORT_REQUEST_FAIL = 'REPUTATION_INCREASES_REPORT_REQUEST_FAIL';

export const REPUTATION_DECREASES_REPORT_REQUEST = 'REPUTATION_DECREASES_REPORT_REQUEST';
export const REPUTATION_DECREASES_REPORT_REQUEST_SUCCESS = 'REPUTATION_DECREASES_REPORT_REQUEST_SUCCESS';
export const REPUTATION_DECREASES_REPORT_REQUEST_FAIL = 'REPUTATION_DECREASES_REPORT_REQUEST_FAIL';
