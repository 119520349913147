export const CONTENT_TESTS_REQUEST = 'CONTENT_TESTS_REQUEST';
export const CONTENT_TESTS_SUCCESS = 'CONTENT_TESTS_SUCCESS';
export const CONTENT_TESTS_FAIL = 'CONTENT_TESTS_FAIL';

export const CONTENT_TESTS_CREATE_REQUEST_UNLOAD = 'CONTENT_TESTS_CREATE_REQUEST_UNLOAD';
export const CONTENT_TESTS_CREATE_REQUEST = 'CONTENT_TESTS_CREATE_REQUEST';
export const CONTENT_TESTS_CREATE_SUCCESS = 'CONTENT_TESTS_CREATE_SUCCESS';
export const CONTENT_TESTS_CREATE_FAIL = 'CONTENT_TESTS_CREATE_FAIL';

export const CONTENT_TESTS_DELETE_REQUEST = 'CONTENT_TESTS_DELETE_REQUEST';
export const CONTENT_TESTS_DELETE_SUCCESS = 'CONTENT_TESTS_DELETE_SUCCESS';
export const CONTENT_TESTS_DELETE_FAIL = 'CONTENT_TESTS_DELETE_FAIL';

export const CONTENT_TESTS_DRAFT_REQUEST = 'CONTENT_TESTS_DRAFT_REQUEST';
export const CONTENT_TESTS_DRAFT_SUCCESS = 'CONTENT_TESTS_DRAFT_SUCCESS';
export const CONTENT_TESTS_DRAFT_FAIL = 'CONTENT_TESTS_DRAFT_FAIL';

export const CONTENT_TESTS_FINISH_REQUEST = 'CONTENT_TESTS_FINISH_REQUEST';
export const CONTENT_TESTS_FINISH_SUCCESS = 'CONTENT_TESTS_FINISH_SUCCESS';
export const CONTENT_TESTS_FINISH_SUCCESS_CLEAR = 'CONTENT_TESTS_FINISH_SUCCESS_CLEAR';
export const CONTENT_TESTS_FINISH_FAIL = 'CONTENT_TESTS_FINISH_FAIL';

export const CONCURRENT_CONTENT_TESTS_REQUEST = 'CONCURRENT_CONTENT_TESTS_REQUEST';
export const CONCURRENT_CONTENT_TESTS_SUCCESS = 'CONCURRENT_CONTENT_TESTS_SUCCESS';
export const CONCURRENT_CONTENT_TESTS_FAIL    = 'CONCURRENT_CONTENT_TESTS_FAIL';

export const CONCURRENT_CONTENT_TESTS_SAVE_REQUEST = 'CONCURRENT_CONTENT_TESTS_SAVE_REQUEST';
export const CONCURRENT_CONTENT_TESTS_SAVE_SUCCESS = 'CONCURRENT_CONTENT_TESTS_SAVE_SUCCESS';
export const CONCURRENT_CONTENT_TESTS_SAVE_FAIL    = 'CONCURRENT_CONTENT_TESTS_SAVE_FAIL';
