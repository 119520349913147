import {
  GET_CURRENT_PLAN_FAIL,
  GET_CURRENT_PLAN_REQUEST,
  GET_CURRENT_PLAN_SUCCESS,
  PLAN_ALL_DETAILS_REQUEST,
  PLAN_ALL_DETAILS_REQUEST_FAIL,
  PLAN_ALL_DETAILS_REQUEST_SUCCESS,
  PLAN_DETAILS_REQUEST,
  PLAN_DETAILS_REQUEST_FAIL,
  PLAN_DETAILS_REQUEST_SUCCESS,
  USER_PLAN_SAVE_FAIL,
  USER_PLAN_SAVE_REQUEST,
  USER_PLAN_SAVE_SUCCESS,
} from '../Constants/planDetailsConstant';

import AxiosApi from '../../config/axios-api';

const planDetailsAction = (userId, type) => async (dispatch) => {
  try {
    if (type !== 'update') dispatch({ type: PLAN_DETAILS_REQUEST, payload: userId });
    const { data } = await AxiosApi.get('billing/get-plan-details/' + userId);
    dispatch({ type: PLAN_DETAILS_REQUEST_SUCCESS, payload: data.result });
  } catch (error) {
    dispatch({ type: PLAN_DETAILS_REQUEST_FAIL, payload: error });
  }
};

const planAllDetailsAction = () => async (dispatch) => {
  try {
    dispatch({ type: PLAN_ALL_DETAILS_REQUEST });
    let hasPlan = 'false';
    let userId = localStorage.getItem('userId');
    const serializedState = localStorage.getItem('state');
    if (serializedState !== null && userId) {
      let planData = JSON.parse(serializedState);
      if (planData?.planDetails?.planData?.planData?.records[0]?.name) hasPlan = 'true';
    }
    const { data } = await AxiosApi.get('billing/get-all-plans-details/?hasPlan=' + hasPlan);
    dispatch({ type: PLAN_ALL_DETAILS_REQUEST_SUCCESS, payload: data.result });
  } catch (error) {
    dispatch({ type: PLAN_ALL_DETAILS_REQUEST_FAIL, payload: error.message });
  }
};

const getCurrentPlanAction =
  (companyId = '') =>
  async (dispatch) => {
    try {
      dispatch({ type: GET_CURRENT_PLAN_REQUEST, payload: null });
      const { data } = await AxiosApi.get(`billing/get-current-plan/${companyId}`);
      dispatch({ type: GET_CURRENT_PLAN_SUCCESS, payload: data?.result });
    } catch (error) {
      dispatch({ type: GET_CURRENT_PLAN_FAIL, payload: error.message });
    }
  };

const userPlanSaveAction =
  (companyId = '', postData) =>
  async (dispatch) => {
    try {
      dispatch({ type: USER_PLAN_SAVE_REQUEST, payload: null });
      const { data } = await AxiosApi.post(`billing/save-current-plan/${companyId}`, postData);
      dispatch({ type: USER_PLAN_SAVE_SUCCESS, payload: data.result });
    } catch (error) {
      dispatch({ type: USER_PLAN_SAVE_FAIL, payload: error });
    }
  };

export { planDetailsAction, planAllDetailsAction, getCurrentPlanAction, userPlanSaveAction };
