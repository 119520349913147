import {
  GET_CURRENT_PLAN_FAIL,
  GET_CURRENT_PLAN_REQUEST,
  GET_CURRENT_PLAN_SUCCESS,
  PLAN_ALL_DETAILS_REQUEST,
  PLAN_ALL_DETAILS_REQUEST_FAIL,
  PLAN_ALL_DETAILS_REQUEST_SUCCESS,
  PLAN_DETAILS_REQUEST,
  PLAN_DETAILS_REQUEST_FAIL,
  PLAN_DETAILS_REQUEST_SUCCESS,
  USER_PLAN_SAVE_FAIL,
  USER_PLAN_SAVE_REQUEST,
  USER_PLAN_SAVE_SUCCESS,
} from "../Constants/planDetailsConstant";

function  planDetailsReducer(state = {planData: []}, action) {
  switch (action.type) {
    case PLAN_DETAILS_REQUEST:
      return { loading: true, planData: [] };
    case PLAN_DETAILS_REQUEST_SUCCESS:
      return { loading: false, planData: action.payload };
    case PLAN_DETAILS_REQUEST_FAIL:
      return { loading: false, error: action.payload };
    default: return state;
  }
}

function  planAllDetailsReducer(state = {planAllData: []}, action) {
  switch (action.type) {
    case PLAN_ALL_DETAILS_REQUEST:
      return { loading: true, planAllData: [] };
    case PLAN_ALL_DETAILS_REQUEST_SUCCESS:
      return { loading: false, planAllData: action.payload };
    case PLAN_ALL_DETAILS_REQUEST_FAIL:
      return { loading: false, error: action.payload };
    default: return state;
  }
}

function  getCurrentPlanReducer(state = {currentPlanData: null}, action) {
  switch (action.type) {
    case GET_CURRENT_PLAN_REQUEST:
      return { loading: true, currentPlanData: null };
    case GET_CURRENT_PLAN_SUCCESS:
      return { loading: false, currentPlanData: action.payload };
    case GET_CURRENT_PLAN_FAIL:
      return { loading: false, error: action.payload };
    default: return state;
  }
}

function  userPlanSaveReducer(state = {userPlanSaveData: null}, action) {
  switch (action.type) {
    case USER_PLAN_SAVE_REQUEST:
      return { loading: true, userPlanSaveData: null };
    case USER_PLAN_SAVE_SUCCESS:
      return { loading: false, userPlanSaveData: action.payload };
    case USER_PLAN_SAVE_FAIL:
      return { loading: false, error: action.payload };
    default: return state;
  }
}

export {
  planDetailsReducer,
  planAllDetailsReducer,
  getCurrentPlanReducer,
  userPlanSaveReducer
}