import { ADMIN_USERS, USER_MAILBOXES } from '../Routes/Constants/RouteUrls';
import { Button, Checkbox, Form, Image, Input, Spin, message } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import React, { useRef, useState } from 'react';

import { Auth } from 'aws-amplify';
import AxiosApi from '../config/axios-api';
import { ERROR_EMAIL } from '../Constants/Email';
import { planDetailsAction } from '../Store/Actions/planDetailsActions';
import { useDispatch } from 'react-redux';

const LoginScreen = () => {
  const inputRef = useRef(null);
  const [email, setEmail] = useState('');
  const [forgotEmail, setForgotEmail] = useState('');
  const [password, setPassword] = useState('');
  const [visible, setVisible] = useState(false);
  const [visibleReset, setVisibleReset] = useState(false);
  const [loader, setloader] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const sharedProps = {
    style: {
      width: '100%',
    },
    initialvalue: 'admin2@allegrow.com',
    ref: inputRef,
  };

  const userId = localStorage.getItem('userId');
  const companyId = localStorage.getItem('companyId');
  if (userId && companyId) {
    history.push(ADMIN_USERS);
  }

  const [form] = Form.useForm();
  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 23,
    },
  };

  const tailLayout = {
    wrapperCol: {
      offset: 0,
      span: 23,
    },
  };

  const urlParams = new URLSearchParams(window.location.search);
  const demoData = urlParams.get('demo-data');
  let user;

  const onFinish = async (values) => {
    setloader(true);
    let email = values.email;
    try {
      localStorage.clear();
      user = await Auth.signIn(values.email, values.password).catch(async (error) => {
        if (error.name === 'UserNotFoundException') {
          email = values.email.toLowerCase();
          user = await Auth.signIn(email, values.password);
          return user;
        }
        throw error;
      });

      if (user) {
        if (demoData) localStorage.setItem('demoData', 1);
        localStorage.setItem('AuthenticationResult', JSON.stringify(user));
        let getJwtToken = user.signInUserSession.idToken.jwtToken;
        let cognitoGroups = user.signInUserSession.idToken.payload['cognito:groups'];
        if (!cognitoGroups || !cognitoGroups.includes('admin')) {
          await Auth.signOut({ global: true });
          localStorage.clear();
          throw { message: 'Please login with admin user only' };
        }
        AxiosApi.defaults.headers.common['Authorization'] = `Bearer ${getJwtToken}`;
        try {
          if (!user.attributes['custom:user_id'] || !user.attributes['custom:company_id']) {
            const userDetails = await AxiosApi.get('user-details/' + email);
            if (
              userDetails.data.result.userData.records &&
              userDetails.data.result.userData.records.length
            ) {
              await Auth.updateUserAttributes(user, {
                'custom:user_id': '' + userDetails.data.result.userData.records[0].id,
                'custom:company_id': '' + userDetails.data.result.userData.records[0].company_id,
              });
              localStorage.setItem('userId', userDetails.data.result.userData.records[0].id);
              localStorage.setItem(
                'companyId',
                userDetails.data.result.userData.records[0].company_id,
              );
            }
          } else {
            localStorage.setItem('userId', user.attributes['custom:user_id']);
            localStorage.setItem('companyId', user.attributes['custom:company_id']);
          }
        } catch (e) {}
        history.push(ADMIN_USERS);
      }
    } catch (e) {
      setloader(false);
      if (e.message === 'User is not confirmed.') {
        e.message = (
          <>
            User not verified: Please look for a verification email from {ERROR_EMAIL}
            <p> and click the link inside it to verify your account.</p>
          </>
        );
      }
      message.warning(e.message, 10);
    }
  };
  const openFogortPasswordModal = () => {
    setVisible(true);
  };
  const onFinishFailed = (errorInfo) => {};

  return (
    <div className={`p-3 ns-container  bg-gradient-to-r from-green-400 to-blue-500`}>
      <main className="w-1/3 main">
        <div className="ns-rounded-lg bg-gradient-to-r to-gray-100 from-white p-10 pt-0 pl-14 pb-5 rounded-2xl">
          <a
            href="https://www.allegrow.co/"
            target="_blank"
            rel="noopener noreferrer"
            className="flex-horizontal-center">
            <Image
              className="mt-5"
              preview={false}
              src="/images/logo_allegrow.svg"
              width="150"
              height="80"
              alt="Logo"
            />
          </a>
          <div className="w-46 pb-10">
            <h6 className="">Welcome to Admin Panel. Please Log in below.</h6>
          </div>
          <div className="w-46">
            <Spin tip="Login..." spinning={loader}>
              <Form
                {...layout}
                form={form}
                name="basic"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}>
                <Form.Item
                  autoFocus
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your email!',
                      type: 'email',
                    },
                  ]}
                  value={''}>
                  <Input
                    {...sharedProps}
                    autoComplete="false"
                    placeholder="Email"
                    className="float-left rounded-md"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                  ]}
                  value={''}>
                  <Input.Password
                    autoComplete="current-password"
                    placeholder="Password"
                    className="rounded-md"
                  />
                </Form.Item>

                <Form.Item className="mb-3">
                  <Form.Item className="w-40" name="remember" valuePropName="checked" noStyle>
                    <Checkbox className="float-left">Remember me</Checkbox>
                  </Form.Item>
                </Form.Item>
                <Form.Item className="" {...tailLayout}>
                  <Button
                    type="primary"
                    className="w-full btn-gredient-green-blue rounded-md"
                    size="large"
                    htmlType="submit">
                    Log In
                  </Button>
                </Form.Item>
              </Form>
            </Spin>
          </div>
        </div>
      </main>
    </div>
  );
};

export default LoginScreen;
