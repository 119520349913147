export const USER_INSIGHTS_REQUEST = 'USER_INSIGHTS_REQUEST';
export const USER_INSIGHTS_REQUEST_SUCCESS = 'USER_INSIGHTS_REQUEST_SUCCESS';
export const USER_INSIGHTS_REQUEST_FAIL = 'USER_INSIGHTS_REQUEST_FAIL';

export const MAILBOX_INSIGHTS_REQUEST = 'MAILBOX_INSIGHTS_REQUEST';
export const MAILBOX_INSIGHTS_REQUEST_SUCCESS = 'MAILBOX_INSIGHTS_REQUEST_SUCCESS';
export const MAILBOX_INSIGHTS_REQUEST_FAIL = 'MAILBOX_INSIGHTS_REQUEST_FAIL';

export const ARCHIVED_INSIGHTS_REQUEST = 'ARCHIVED_INSIGHTS_REQUEST';
export const ARCHIVED_INSIGHTS_REQUEST_SUCCESS = 'ARCHIVED_INSIGHTS_REQUEST_SUCCESS';
export const ARCHIVED_INSIGHTS_REQUEST_FAIL = 'ARCHIVED_INSIGHTS_REQUEST_FAIL';

export const DOMAIN_INSIGHTS_COUNT_REQUEST = 'DOMAIN_INSIGHTS_COUNT_REQUEST';
export const DOMAIN_INSIGHTS_COUNT_REQUEST_SUCCESS = 'DOMAIN_INSIGHTS_COUNT_REQUEST_SUCCESS';
export const DOMAIN_INSIGHTS_COUNT_REQUEST_FAIL = 'DOMAIN_INSIGHTS_COUNT_REQUEST_FAIL';

export const USER_INSIGHTS_ALL_REQUEST = 'USER_INSIGHTS_ALL_REQUEST';
export const USER_INSIGHTS_ALL_REQUEST_SUCCESS = 'USER_INSIGHTS_ALL_REQUEST_SUCCESS';
export const USER_INSIGHTS_ALL_REQUEST_FAIL = 'USER_INSIGHTS_ALL_REQUEST_FAIL';

export const USER_INSIGHTS_EDIT_REQUEST = 'USER_INSIGHTS_EDIT_REQUEST';
export const USER_INSIGHTS_EDIT_REQUEST_SUCCESS = 'USER_INSIGHTS_EDIT_REQUEST_SUCCESS';
export const USER_INSIGHTS_EDIT_REQUEST_FAIL = 'USER_INSIGHTS_EDIT_REQUEST_FAIL';

export const USER_INSIGHTS_SAVE_REQUEST = 'USER_INSIGHTS_SAVE_REQUEST';
export const USER_INSIGHTS_SAVE_REQUEST_CLOSE = 'USER_INSIGHTS_SAVE_REQUEST_CLOSE';
export const USER_INSIGHTS_SAVE_REQUEST_SUCCESS = 'USER_INSIGHTS_SAVE_REQUEST_SUCCESS';
export const USER_INSIGHTS_SAVE_REQUEST_FAIL = 'USER_INSIGHTS_SAVE_REQUEST_FAIL';