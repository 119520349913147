import { 
  CLOSE_API_REQUEST, CLOSE_API_REQUEST_SUCCESS, CLOSE_API_REQUEST_FAIL,
  USER_MAILBOX_RECOMMEND, USER_MAILBOX_RECOMMEND_SUCCESS, USER_MAILBOX_RECOMMEND_FAIL,
} from "../Constants/integrationConstants";

function  closeApiReducer(state = {closeApiData: null}, action) {
  switch (action.type) {
    case CLOSE_API_REQUEST:
      return { loading: true, closeApiData: null };
    case CLOSE_API_REQUEST_SUCCESS:
      return { loading: false, closeApiData: action.payload };
    case CLOSE_API_REQUEST_FAIL:
      return { loading: false, closeApiError: action.payload };
    default: return state;
  }
}
function  userMailboxRecommendReducer(state = {userMailboxRecommendData: null}, action) {
  switch (action.type) {
    case USER_MAILBOX_RECOMMEND:
      return { loading: true, userMailboxRecommendData: null };
    case USER_MAILBOX_RECOMMEND_SUCCESS:
      return { loading: false, userMailboxRecommendData: action.payload };
    case USER_MAILBOX_RECOMMEND_FAIL:
      return { loading: false, userMailboxRecommendError: action.payload };
    default: return state;
  }
}

export {
  closeApiReducer, userMailboxRecommendReducer,
}