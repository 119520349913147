import {
  ARCHIVED_INSIGHTS_REQUEST,
  ARCHIVED_INSIGHTS_REQUEST_FAIL,
  ARCHIVED_INSIGHTS_REQUEST_SUCCESS,
  DOMAIN_INSIGHTS_COUNT_REQUEST,
  DOMAIN_INSIGHTS_COUNT_REQUEST_FAIL,
  DOMAIN_INSIGHTS_COUNT_REQUEST_SUCCESS,
  MAILBOX_INSIGHTS_REQUEST,
  MAILBOX_INSIGHTS_REQUEST_FAIL,
  MAILBOX_INSIGHTS_REQUEST_SUCCESS,
  USER_INSIGHTS_ALL_REQUEST,
  USER_INSIGHTS_ALL_REQUEST_FAIL,
  USER_INSIGHTS_ALL_REQUEST_SUCCESS,
  USER_INSIGHTS_EDIT_REQUEST,
  USER_INSIGHTS_EDIT_REQUEST_FAIL,
  USER_INSIGHTS_EDIT_REQUEST_SUCCESS,
  USER_INSIGHTS_REQUEST,
  USER_INSIGHTS_REQUEST_FAIL,
  USER_INSIGHTS_REQUEST_SUCCESS,
  USER_INSIGHTS_SAVE_REQUEST,
  USER_INSIGHTS_SAVE_REQUEST_CLOSE,
  USER_INSIGHTS_SAVE_REQUEST_FAIL,
  USER_INSIGHTS_SAVE_REQUEST_SUCCESS
} from "../Constants/insightsConstants";

function userInsightsReducer(state = {userInsights: []}, action) {
  switch (action.type) {
    case USER_INSIGHTS_REQUEST:
      return { loading: true, userInsights: [] };
    case USER_INSIGHTS_REQUEST_SUCCESS:
      return { loading: false, userInsights: action.payload };
    case USER_INSIGHTS_REQUEST_FAIL:
      return { loading: false, error: action.payload };
    default: return state;
  }
}

function mailboxInsightsReducer(state = {mailboxInsights: []}, action) {
  switch (action.type) {
    case MAILBOX_INSIGHTS_REQUEST:
      return { loading: true, mailboxInsights: [] };
    case MAILBOX_INSIGHTS_REQUEST_SUCCESS:
      return { loading: false, mailboxInsights: action.payload };
    case MAILBOX_INSIGHTS_REQUEST_FAIL:
      return { loading: false, error: action.payload };
    default: return state;
  }
}

function archivedInsightsReducer(state = {archivedInsights: []}, action) {
  switch (action.type) {
    case ARCHIVED_INSIGHTS_REQUEST:
      return { loading: true, archivedInsights: [] };
    case ARCHIVED_INSIGHTS_REQUEST_SUCCESS:
      return { loading: false, archivedInsights: action.payload };
    case ARCHIVED_INSIGHTS_REQUEST_FAIL:
      return { loading: false, error: action.payload };
    default: return state;
  }
}

function domainInsightsCountReducer(state = {domainInsightsCount: []}, action) {
  switch (action.type) {
    case DOMAIN_INSIGHTS_COUNT_REQUEST:
      return { loading: true, domainInsightsCount: [] };
    case DOMAIN_INSIGHTS_COUNT_REQUEST_SUCCESS:
      return { loading: false, domainInsightsCount: action.payload };
    case DOMAIN_INSIGHTS_COUNT_REQUEST_FAIL:
      return { loading: false, error: action.payload };
    default: return state;
  }
}

function allInsightsReducer(state = {allInsights: null}, action) {
  switch (action.type) {
    case USER_INSIGHTS_ALL_REQUEST:
      return { loading: true, allInsights: null };
    case USER_INSIGHTS_ALL_REQUEST_SUCCESS:
      return { loading: false, allInsights: action.payload.result.insights };
    case USER_INSIGHTS_ALL_REQUEST_FAIL:
      return { loading: false, error: action.payload };
    default: return state;
  }
}

function editInsightsReducer(state = {editInsightsData: null}, action) {
  switch (action.type) {
    case USER_INSIGHTS_EDIT_REQUEST:
      return { loading: true, editInsightsData: null };
    case USER_INSIGHTS_EDIT_REQUEST_SUCCESS:
      return { loading: false, editInsightsData: action.payload.result.insight };
    case USER_INSIGHTS_EDIT_REQUEST_FAIL:
      return { loading: false, error: action.payload };
    default: return state;
  }
}

function saveInsightsReducer(state = {saveInsightsData: null}, action) {
  switch (action.type) {
    case USER_INSIGHTS_SAVE_REQUEST:
      return { loading: true, saveInsightsData: null };
    case USER_INSIGHTS_SAVE_REQUEST_CLOSE:
      return { loading: false, saveInsightsData: null };
    case USER_INSIGHTS_SAVE_REQUEST_SUCCESS:
      return { loading: false, saveInsightsData: action.payload };
    case USER_INSIGHTS_SAVE_REQUEST_FAIL:
      return { loading: false, error: action.payload };
    default: return state;
  }
}


export {
  userInsightsReducer,mailboxInsightsReducer,archivedInsightsReducer,domainInsightsCountReducer,
  allInsightsReducer, editInsightsReducer, saveInsightsReducer
}