import { 
  CONVERSATION_REQUEST, CONVERSATION_REQUEST_SUCCESS,CONVERSATION_REQUEST_FAIL,
  CONVERSATION_SAVE_REQUEST, CONVERSATION_SAVE_REQUEST_SUCCESS, CONVERSATION_SAVE_REQUEST_FAIL, CONVERSATION_SAVE_REQUEST_CLOSE,
  CONVERSATION_DELETE_REQUEST, CONVERSATION_DELETE_SUCCESS, CONVERSATION_DELETE_FAIL
 } from "../Constants/conversationConstant";

function  conversationReducer(state = {conversationData: []}, action) {
  switch (action.type) {
    case CONVERSATION_REQUEST:
      return { loading: true, conversationData: null };
    case CONVERSATION_REQUEST_SUCCESS:
      return { loading: false, conversationData: action.payload };
    case CONVERSATION_REQUEST_FAIL:
      return { loading: false, error: action.payload };
    default: return state;
  }
}

function  conversationSaveReducer(state = {conversationSaveData: []}, action) {
  switch (action.type) {
    case CONVERSATION_SAVE_REQUEST:
      return { loading: true, conversationSaveData: null };
    case CONVERSATION_SAVE_REQUEST_CLOSE:
      return { loading: false, conversationSaveData: null };
    case CONVERSATION_SAVE_REQUEST_SUCCESS:
      return { loading: false, conversationSaveData: action.payload };
    case CONVERSATION_SAVE_REQUEST_FAIL:
      return { loading: false, error: action.payload };
    default: return state;
  }
}

function  conversationDeleteReducer(state = {conversationDelete: null}, action) {
  switch (action.type) {
    case CONVERSATION_DELETE_REQUEST:
      return { loading: true, conversationDelete: null };
    case CONVERSATION_DELETE_SUCCESS:
      return { loading: false, conversationDelete: action.payload };
    case CONVERSATION_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default: return state;
  }
}

export {
  conversationReducer, conversationSaveReducer, conversationDeleteReducer
}