import {
  ADMIN_ADD_CONVERSATIONS,
  ADMIN_ADD_USER_INSIGHTS,
  ADMIN_CONVERSATIONS,
  ADMIN_DOMAIN_MOVEMENTS,
  ADMIN_EDIT_CONVERSATIONS,
  ADMIN_EDIT_USER_INSIGHTS,
  ADMIN_STATS,
  ADMIN_USERS,
  ADMIN_USERS_DETAILS_ID,
  ADMIN_USER_INSIGHTS,
  USER_CONTENT_TESTS,
  USER_CREATE_CONTENT_TESTS,
  USER_CREATE_CONTENT_TESTS_ID,
  USER_EDIT_CONTENT_TESTS_ID,
  USER_EDIT_MAILBOX_ID,
  USER_INSIGHTS,
  USER_MAILBOXES,
  USER_PROFILE,
  USER_SECURITY,
  USER_SENDING_PATTERN_ID,
  USER_SETTINGS,
  USER_SETTINGS_INTEGRATIONS,
  USER_SUBSCRIPTION,
  USER_TEAM,
} from '../Routes/Constants/RouteUrls';

import React from 'react';

const AverageSpamRateScreen = React.lazy(() =>
  import('../Screens/Admin/Stats/AverageSpamRateScreen'),
);
const DomainMovementScreen = React.lazy(() =>
  import('../Screens/Admin/DomainMovements/DomainMovementsScreen'),
);
const UserInsightsScreen = React.lazy(() =>
  import('../Screens/Admin/UserInsights/UserInsightsScreen'),
);
const AddUserInsightsScreen = React.lazy(() =>
  import('../Screens/Admin/UserInsights/AddInsightsScreen'),
);
const UsersScreen = React.lazy(() => import('../Screens/Admin/Users/UsersScreen'));
const UserDetailsScreen = React.lazy(() => import('../Screens/Admin/Users/UserDetailsScreen'));
const ConversationsScreen = React.lazy(() =>
  import('../Screens/Admin/Conversations/ConversationsScreen'),
);
const AddConversationScreen = React.lazy(() =>
  import('../Screens/Admin/Conversations/AddConversationScreen'),
);
const MailboxScreen = React.lazy(() => import('../Screens/MailboxScreen'));
const EditMailBoxScreen = React.lazy(() => import('../Screens/EditMailBoxScreen'));
const SendingPatternScreen = React.lazy(() =>
  import('../Screens/SendingPattern/SendingPatternScreen'),
);
const ContentTestsScreen = React.lazy(() => import('../Screens/ContentTestsScreen'));
const EditContentTestsScreen = React.lazy(() => import('../Screens/EditContentTestsScreen'));
const CreateContentTestsScreen = React.lazy(() => import('../Screens/CreateContentTestsScreen'));
const InsightsScreen = React.lazy(() => import('../Screens/InsightsScreen'));
const SettingsScreen = React.lazy(() => import('../Screens/SettingsScreen'));
const MyProfileScreen = React.lazy(() => import('../Screens/MyProfileScreen'));
const SecurityScreen = React.lazy(() => import('../Screens/SecurityScreen'));
const SubscriptionScreen = React.lazy(() => import('../Screens/SubscriptionScreen'));
const TeamScreen = React.lazy(() => import('../Screens/TeamScreen'));
const PageNotFoundScreen = React.lazy(() => import('../Screens/PageNotFoundScreen'));

const ProtectedRoutes = [
  { path: ADMIN_STATS, component: AverageSpamRateScreen, exact: true },
  { path: ADMIN_DOMAIN_MOVEMENTS, component: DomainMovementScreen, exact: true },
  { path: ADMIN_USER_INSIGHTS, component: UserInsightsScreen, exact: true },
  { path: ADMIN_ADD_USER_INSIGHTS, component: AddUserInsightsScreen, exact: true },
  { path: ADMIN_EDIT_USER_INSIGHTS, component: AddUserInsightsScreen, exact: true },
  { path: ADMIN_USERS, component: UsersScreen, exact: true },
  { path: ADMIN_CONVERSATIONS, component: ConversationsScreen, exact: true },
  { path: ADMIN_ADD_CONVERSATIONS, component: AddConversationScreen, exact: true },
  { path: ADMIN_EDIT_CONVERSATIONS, component: AddConversationScreen, exact: true },
  { path: ADMIN_USERS_DETAILS_ID, component: UserDetailsScreen, exact: true },
  { path: USER_MAILBOXES, component: MailboxScreen, exact: true },
  { path: USER_EDIT_MAILBOX_ID, component: EditMailBoxScreen, exact: true },
  { path: USER_SENDING_PATTERN_ID, component: SendingPatternScreen, exact: true },
  { path: USER_CONTENT_TESTS, component: ContentTestsScreen, exact: true },
  { path: USER_EDIT_CONTENT_TESTS_ID, component: EditContentTestsScreen, exact: true },
  { path: USER_CREATE_CONTENT_TESTS, component: CreateContentTestsScreen, exact: true },
  { path: USER_CREATE_CONTENT_TESTS_ID, component: CreateContentTestsScreen, exact: true },
  { path: USER_INSIGHTS, component: InsightsScreen, exact: true },
  { path: USER_PROFILE, component: MyProfileScreen },
  { path: USER_TEAM, component: TeamScreen },
  { path: USER_SECURITY, component: SecurityScreen },
  { path: USER_SUBSCRIPTION, component: SubscriptionScreen },
  { path: USER_SETTINGS, component: SettingsScreen },
  { path: USER_SETTINGS_INTEGRATIONS, component: SettingsScreen },
  { path: '', component: PageNotFoundScreen },
];

export default ProtectedRoutes;
