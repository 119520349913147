import {
  PROCESSED_EMAIL_REQUEST,
  PROCESSED_EMAIL_REQUEST_FAIL,
  PROCESSED_EMAIL_REQUEST_SUCCESS,
  REPUTATION_DECREASES_REPORT_REQUEST,
  REPUTATION_DECREASES_REPORT_REQUEST_FAIL,
  REPUTATION_DECREASES_REPORT_REQUEST_SUCCESS,
  REPUTATION_INCREASES_REPORT_REQUEST,
  REPUTATION_INCREASES_REPORT_REQUEST_FAIL,
  REPUTATION_INCREASES_REPORT_REQUEST_SUCCESS,
  SPAM_RATES_REQUEST,
  SPAM_RATES_REQUEST_FAIL,
  SPAM_RATES_REQUEST_SUCCESS,
} from "../Constants/analyticsConstant";

function  spamRatesReducer(state = {spamRatesData: null}, action) {
  switch (action.type) {
    case SPAM_RATES_REQUEST:
      return { loading: true, spamRatesData: null };
    case SPAM_RATES_REQUEST_SUCCESS:
      return { loading: false, spamRatesData: action.payload };
    case SPAM_RATES_REQUEST_FAIL:
      return { loading: false, error: action.payload };
    default: return state;
  }
}

function  processedEmailsReducer(state = {processedEmailsData: null}, action) {
  switch (action.type) {
    case PROCESSED_EMAIL_REQUEST:
      return { loading: true, processedEmailsData: null };
    case PROCESSED_EMAIL_REQUEST_SUCCESS:
      return { loading: false, processedEmailsData: action.payload };
    case PROCESSED_EMAIL_REQUEST_FAIL:
      return { loading: false, error: action.payload };
    default: return state;
  }
}

function  getReputationIncreasesReportReducer(state = {getReputationIncreasesData: null}, action) {
  switch (action.type) {
    case REPUTATION_INCREASES_REPORT_REQUEST:
      return { loading: true, getReputationIncreasesData: null };
    case REPUTATION_INCREASES_REPORT_REQUEST_SUCCESS:
      return { loading: false, getReputationIncreasesData: action.payload };
    case REPUTATION_INCREASES_REPORT_REQUEST_FAIL:
      return { loading: false, error: action.payload };
    default: return state;
  }
}

function  getReputationDecreasesReportReducer(state = {getReputationDecreasesData: null}, action) {
  switch (action.type) {
    case REPUTATION_DECREASES_REPORT_REQUEST:
      return { loading: true, getReputationDecreasesData: null };
    case REPUTATION_DECREASES_REPORT_REQUEST_SUCCESS:
      return { loading: false, getReputationDecreasesData: action.payload };
    case REPUTATION_DECREASES_REPORT_REQUEST_FAIL:
      return { loading: false, error: action.payload };
    default: return state;
  }
}

export {
  spamRatesReducer,
  processedEmailsReducer,
  getReputationIncreasesReportReducer,
  getReputationDecreasesReportReducer
}