import React, { useEffect } from 'react'
import { Auth } from "aws-amplify";
import { useHistory } from 'react-router-dom';

const LogoutScreen = () => {

  const history = useHistory();
  const logout = async () => {
    localStorage.clear();
    await Auth.signOut({ global: true });
    history.push("/");
  }

  useEffect(() => {
    logout();
  })

  return <div></div>
}

export default LogoutScreen