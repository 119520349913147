import React from 'react'
import { Result, Button } from 'antd';
import { Link } from "react-router-dom";
import { ADMIN_USERS } from '../Routes/Constants/RouteUrls';

const PageNotFoundScreen = () => {
    const userId = localStorage.getItem("userId")
    return (
        <div>
            <Result
                    status="404"
                    title="404"
                    subTitle="Sorry, the page you visited does not exist."
                    extra={<Link to={userId ? ADMIN_USERS : ''}><Button type="primary">Back Home</Button></Link>}
                />
        </div>
    )
}

export default PageNotFoundScreen